module.exports = {
  comm: {
    home: 'Accueil',
    pricing: 'Tarification',
    blog: 'Clonage de voix et API',
    language: 'Langue:',
    select_language: 'Choisir la langue',
    txt2voice: 'Texte en parole',
    voice2txt: 'Parole en texte',
    voiceclone: 'Clonage de voix',
    video2txt: 'Vidéo en texte',
    footer_help: 'Besoin d\'aide ? Écrivez-nous :',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Connexion',
    login_desc: 'Connectez-vous pour accéder à votre compte TikTok Voice Generator',
    logout: 'Déconnexion',
  },
  txt2voice: {
    title: 'Générateur de voix TikTok: Générer des voix AI TikTok amusantes',
    description: 'Générez et téléchargez gratuitement des voix AI TikTok amusantes telles que la voix de Jessie, la voix de C3PO, la voix de Ghostface...',
    keywords: 'Générateur de voix TikTok, Voix AI TikTok, Générateur de voix Tik Tok',
    main_title_p01: 'Générateur de voix TikTok',
    main_title_p02: 'Générer des voix TikTok amusantes: voix de Jessie, voix de C3PO, voix de Ghostface',
    main_textarea_holder: 'Tapez ou collez du texte ici',
    main_genvoice: 'générer',
    main_generating: 'génération',
    main_input_empty: 'veuillez taper ou coller du texte',
    daily_usage_limit_msg: "1) Vous avez atteint le maximum d'utilisation quotidienne. Veuillez revenir demain. 2) Si vous avez besoin de plus de crédits d'utilisation en urgence, veuillez nous envoyer un e-mail.",
    text_max_prompt1: 'Veuillez limiter le nombre de mots à',
    text_max_prompt2: 'mots ou moins!',
    popup_nologin_title: 'Améliorez votre plan d\'abonnement',
    popup_nologin_desc: 'Débloquez plus de temps de génération de voix en passant à un abonnement premium.',
    popup_nologin_btntext: 'Explorer les plans d\'abonnement',
    popup_nosub_title: 'Améliorez votre plan d\'abonnement',
    popup_nosub_desc: 'Débloquez plus de temps de génération de voix en passant à un abonnement premium.',
    popup_nosub_btntext: 'Explorer les plans d\'abonnement',
    popup_sublimit_title: 'Abonnement épuisé, veuillez mettre à niveau',
    popup_sublimit_desc: 'Améliorez votre expérience avec nos fonctionnalités premium et un accès illimité.',
    popup_sublimit_btntext: 'Aller acheter plus',
  },
  pricing: {
    new_features_alert: "📣 Nous avons lancé les services de 🎙️ Clonage de voix et d'🤖 API pour les membres payants ! (Après le paiement, envoyez un e-mail à tiktokaivoicetool@gmail.com pour obtenir de l'aide sur ces fonctionnalités). De plus, n'hésitez pas à nous contacter par e-mail pour toute demande de personnalisation😃",
    pricing_title: 'Des créateurs individuels aux plus grandes entreprises, nous avons un plan pour vous.',
    pricing_desc: 'Plus de 100 voix naturelles et humaines. Plus de 15 langues et presque les prix les plus bas pour tout le monde',
    sub_free_name: 'Gratuit',
    sub_free_desc: 'Pour les particuliers qui souhaitent essayer l\'audio AI de pointe',
    sub_free_content: [
      'Limite de 1000 caractères par mois',
      '5 générations par jour',
    ],
    sub_starter_name: 'Débutant',
    sub_starter_desc: 'Pour les créateurs qui produisent du contenu premium pour un public mondial',
    sub_starter_content: [
      '1 clone de voix',
      'Limite de 300 000 caractères par mois (environ 7 heures d\'audio)',
      'Téléchargements illimités',
      'Support par e-mail sous 72 heures',
      'Accès prioritaire aux nouvelles voix et fonctionnalités',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Pour les créateurs qui développent leur production de contenu',
    sub_pro_content: [
      'Accès API',
      '3 clones de voix',
      'Limite de 1 000 000 caractères par mois (environ 24 heures d\'audio)',
      'Téléchargements illimités',
      'Support par e-mail sous 48 heures',
      'Accès prioritaire aux nouvelles voix et fonctionnalités',
    ],
    period_year: 'Annuel',
    period_month: 'Mensuel',
    period_month_short: 'mois',
    billed_year: 'Facturé annuellement',
    most_popular: 'Le plus populaire',
    discount_quantity: 'Économisez 48$',
    buy_btntext: 'Commencer',
    pay_succ: 'Paiement réussi !',
    pay_succ_desc: 'Merci pour votre achat. La transaction a été traitée avec succès.',
    pay_succ_btntext: 'Commencer à utiliser TikTok Voice',
    pay_fail: 'Échec du paiement !',
    pay_fail_desc: "Désolé, nous n'avons pas pu traiter votre transaction. Veuillez réessayer ou contacter notre équipe d'assistance (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Retour à la page d\'accueil',
  },
  setting: {
    setting: 'Paramètres',
    setting_title: 'Gérez ici votre compte, votre utilisation et votre abonnement.',
    basic_info: 'Informations de base',
    user_name: 'Nom',
    user_email: 'E-mail',
    user_account: 'Compte',
    user_subscript: 'Plan actuel',
    user_usage: 'Utilisation',
    manage_subscript: 'Gérer l\'abonnement',
    use_limit_promote: "Vous avez utilisé 5000 caractères sur votre limite mensuelle de 10000 caractères.",
    not_logged_in: 'Vous n\'êtes pas connecté',
    go_to_login: 'Aller à la connexion >>',
  },
  login: {
    title: "Générateur de voix TikTok",
    subtitle: "Connectez-vous pour accéder à votre compte TikTok Voice Generator",
    alreadyLoggedIn: "Vous êtes déjà connecté",
    goToHome: "Aller à la page d'accueil"
  },
  faq: {
    quest1: 'Générateur de voix TikTok - Meilleur outil de voix AI TikTok',
    answer1_1: 'Il s\'agit d\'un outil de générateur de voix AI TikTok développé par la dernière technologie tts TikTok, il peut générer des voix TikTok telles que la voix de Jessie (voix féminine), la voix de Siri, la voix de Ghostface, la voix de C3PO (voix de robot), la voix profonde (narrateur), la voix de Skye, la voix chaleureuse, la voix de Bestie, la voix de Héros (voix de Chiris), la voix empathique, la voix sérieuse, la voix de Joey, la voix de Stitch, la voix de Stormtrooper (Star Wars), la voix de Rocket (Guardians of the Galaxy).',
    answer1_2: 'Les voix AI TikTok qui seront bientôt prises en charge incluent : la voix de Derek, la voix de Trickster, la voix d\'Austin Butler, la voix de journaliste, la voix d\'Adam, la voix de chien, la voix de Miley Cyrus, la voix d\'Alexa, la voix d\'extraterrestre, la voix d\'animal, la voix de bébé, la voix d\'ordinateur, la voix de tamia, la voix d\'écho, la voix de NPR.',
    answer1_3: 'De plus, l\'outil de générateur de voix TikTok prend en charge plusieurs autres langues, notamment le chinois, le japonais, le coréen, le vietnamien, le thaï, l\'hindi, le persan, le russe, l\'allemand, le français, le roumain, le tchèque, l\'espagnol, le portugais, le bengali, l\'italien, l\'arabe, l\'ourdou, le chinois traditionnel et le malais.',
    answer1_4: 'Si vous avez besoin d\'urgence d\'une voix spécifique, veuillez m\'envoyer un e-mail.',

quest2: 'Quels sont les avantages du générateur de voix TikTok ?',
    answer2_1: '- L\'outil générateur de voix TikTok peut générer divers types de voix, qui sont souvent utilisées dans les vidéos TikTok.',
    answer2_2: '- C\'est le dernier générateur de voix AI qui peut générer des voix tts TikTok ressemblant à celles d\'humains.',
    answer2_3: '- Il est plus pratique pour l\'édition de vidéos sur un PC.',
    answer2_4: '- Vous pouvez utiliser certaines voix qui ne se trouvent pas actuellement dans l\'application TikTok tts.',

quest3: 'Comment utiliser le générateur de voix TikTok ?',
    answer3_1: 'Le générateur de voix TikTok est très facile à utiliser :',
    answer3_2: '- Choisissez la langue et l\'accent de la voix.',
    answer3_3: '- Tapez le texte à convertir en voix dans la boîte de saisie.',
    answer3_4: '- Appuyez sur le bouton générer et attendez quelques secondes.',
    answer3_5: '- Lisez ou téléchargez la voix AI TikTok.',

quest4: 'Quelles sont les voix TikTok les plus célèbres et les plus amusantes ?',
    answer4_1: 'La voix TikTok masculine la plus célèbre est la voix profonde, officiellement appelée narrateur.',
    answer4_2: 'Pour la voix TikTok la plus amusante, je recommande personnellement la voix de Ghostface et la voix de C3PO. Elles sont souvent utilisées pour doubler des vidéos TikTok amusantes car elles sont très reconnaissables et peuvent rapidement attirer les spectateurs.',

quest5: 'Quelles sont les voix TikTok les plus agaçantes ?',
    answer5_1: 'Je pense qu\'il n\'y a pas vraiment de voix TikTok "agaçante".',
    answer5_2: 'Cela dépend plus du contexte et du contenu où la voix est utilisée, ce qui peut rendre certaines personnes mal à l\'aise. Par exemple, certaines personnes peuvent trouver la voix de Jessie agaçante car elle est apparue trop fréquemment sur TikTok pendant une certaine période. Cependant, en raison de son timbre et de sa qualité, c\'est en fait un excellent effet de voix TikTok. De plus, la doubleuse TikTok derrière est très professionnelle et elle publie également ses propres vidéos sur TikTok.',
    answer5_3: 'Par conséquent, je suggère à tout le monde de mettre de côté les préoccupations et les préjugés, d\'écouter plusieurs fois les voix de cet outil et de trouver patiemment l\'effet de voix TikTok qui vous convient le mieux.',

quest6: 'Comment utiliser le générateur de voix TikTok pour générer la voix de Jessie ?',
    answer6_1: '- Dans le premier menu déroulant de l\'outil générateur de voix TikTok, sélectionnez English (US), puis dans l\'autre menu déroulant, sélectionnez la voix de Jessie (voix féminine).',
    answer6_2: '- Tapez le texte dans la boîte de saisie et cliquez sur le bouton générer.',
    answer6_3: '- Attendez quelques secondes ou jusqu\'à dix secondes, vous entendrez la voix AI. Dans la barre d\'outils, trouvez le bouton avec une flèche vers le bas et cliquez dessus pour télécharger.',

quest7: 'Comment utiliser le générateur de voix TikTok pour générer la voix de C3PO ?',
    answer7_1: '- Dans le premier menu déroulant de l\'outil générateur de voix TikTok, sélectionnez English (US), puis dans l\'autre menu déroulant, sélectionnez la voix de C3PO (voix de robot).',
    answer7_2: '- Tapez le texte dans la boîte de saisie et cliquez sur le bouton générer.',
    answer7_3: '- Attendez quelques secondes ou jusqu\'à dix secondes, vous entendrez la voix AI. Dans la barre d\'outils, trouvez le bouton avec une flèche vers le bas et cliquez dessus pour télécharger.',

quest8: 'Comment utiliser le générateur de voix TikTok pour générer la voix de Ghostface ?',
    answer8_1: '- Dans le premier menu déroulant de l\'outil générateur de voix TikTok, sélectionnez English (US), puis dans l\'autre menu déroulant, sélectionnez la voix de Ghostface.',
    answer8_2: '- Tapez le texte dans la boîte de saisie et cliquez sur le bouton générer.',
    answer8_3: '- Attendez quelques secondes ou jusqu\'à dix secondes, vous entendrez la voix AI. Dans la barre d\'outils, trouvez le bouton avec une flèche vers le bas et cliquez dessus pour télécharger.',

quest9: 'Comment utiliser le générateur de voix TikTok pour générer la voix de Siri ?',
    answer9_1: '- Dans le premier menu déroulant de l\'outil générateur de voix TikTok, sélectionnez English (US), puis dans l\'autre menu déroulant, sélectionnez la voix de Siri.',
    answer9_2: '- Tapez le texte dans la boîte de saisie et cliquez sur le bouton générer.',
    answer9_3: '- Attendez quelques secondes ou jusqu\'à dix secondes, vous entendrez la voix AI. Dans la barre d\'outils, trouvez le bouton avec une flèche vers le bas et cliquez dessus pour télécharger.',

quest10: 'Comment ajouter un effet de voix TikTok sur une vidéo TikTok ?',
    answer10_1: 'Si vous souhaitez savoir comment utiliser les voix officielles de TikTok sur TikTok, je vais bientôt écrire un billet de blog dédié à ce sujet.',
    answer10_2: 'Ici, je vais expliquer comment publier votre voix sur TikTok après l\'avoir générée et téléchargée à partir du générateur de voix TikTok.',
    answer10_3: '1. Si vous avez généré la voix TikTok sur votre PC et souhaitez la télécharger sur TikTok ou toute autre application de montage vidéo sur votre téléphone, vous devez transférer le fichier audio sur votre téléphone. Pour les iPhones, vous pouvez utiliser airDrop pour le transfert. Pour les téléphones Android, je ne suis pas très familier avec ce processus, mais vous trouverez certainement des méthodes et des outils pour le faire.',
    answer10_4: '2. Une fois le fichier audio transféré sur votre téléphone, vous pouvez ouvrir TikTok :',
    answer10_5: '- Appuyez sur le bouton "+" en bas de l\'interface, sélectionnez la vidéo sur votre téléphone.',
    answer10_6: '- Après avoir téléchargé la vidéo, trouvez une icône carrée sur le côté droit de l\'écran pour entrer dans la page de montage.',
    answer10_7: '- En bas de la page, trouvez le bouton "ajouter une voix" et appuyez dessus pour sélectionner le fichier audio que vous venez de transférer.',
    answer10_8: '- Dans cette interface, vous pouvez faire quelques ajustements à la vidéo, puis appuyez sur le bouton dans le coin supérieur droit pour la publier.',

quest11: 'Le TikTok Voice est-il gratuit ?',
    answer11: 'Oui, c\'est un outil gratuit de génération de voix AI TikTok à partir de texte en parole.',

quest12: 'Où puis-je générer une voix TikTok populaire dans ma vidéo TikTok ?',
    answer12: 'Vous pouvez visiter https://tiktokvoice.net/ pour ajouter une voix AI TikTok à votre vidéo.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}